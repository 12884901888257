export const verticallyReorderFloorAreaVisuals = [
  {
    name: "Demo",
    markdown: `![vertically reorder floor area GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const verticallyReorderFloorArea = {
  order: 36,
  name: "Vertically reorder floor areas",
  keyword: "move",
  subcategory: "Properties",
  markdown: `# Vertically reorder floor areas

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  When a {% inlineRouterLink articleId="add-a-land-covering" %}land covering{% /inlineRouterLink %} contains multiple {% inlineRouterLink articleId="add-a-floor-area" %}floor areas{% /inlineRouterLink %}, it can be useful to change the vertical order of the floor areas to better approximate their real-world placement. Read on to learn how to do this in the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.

  {% callout type="tip" %}
  **Tip:** To use this feature the floor areas must be attached to land coverings. If they are attached to {% inlineRouterLink articleId="add-building-levels" %}building levels{% /inlineRouterLink %}, you can {% inlineRouterLink articleId="horizontally-reorder-floor-areas" %}horizontally reorder{% /inlineRouterLink %} and {% inlineRouterLink articleId="relocate-floor-areas" %}relocate{% /inlineRouterLink %} them.
  {% /callout %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="add-a-floor-area" %}floor area{% /inlineRouterLink %} that is attached to a {% inlineRouterLink articleId="add-a-land-covering" %}land covering{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="verticalReorder" /%}.
  4. {% inlineAppIcon iconName="cursor" /%}Click and drag any of the floor areas up or down within the land covering.
  5. Once the order of all floor areas is correct, click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$verticallyReorderFloorAreaVisuals /%}
  `,
};
